import React from 'react';
import { createGlobalStyle } from 'styled-components';
import MainPage from '../pages/MainPage';


const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto', sans-serif;
    background-color: #000000;
    overflow: hidden;
    color: #FFFFFF;
  }
`;

const App = () => (
  <>
    <GlobalStyle />
    <MainPage/>
  </>
);

export default App;
